@charset "utf-8";
@import url('https://fonts.googleapis.com/earlyaccess/nanummyeongjo.css');
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

/* Reset */
html{
	height: 100%;
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	-webkit-text-size-adjust:none;
	-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}
body {
	margin:0;padding:0;font-size:0.75em;height:100%;font-family: 'Pretendard', sans-serif;font-weight:400;word-break: keep-all; background-color: #F1F1F1;
}
html, h1, h2, h3, h4, h5, h6, form, fieldset, img, dl, dt, dd, p {margin:0;padding:0;border:0}
h1, h2, h3, h4, h5, h6 {font-size:1em}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}
header ul, nav ul, footer ul {margin:0;padding:0;list-style:none}
legend {position:absolute;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle}
input, button {margin:0;padding:0;font-size:1em}
button,input[type=button],input[type=submit]{cursor:pointer;}
input[type=text], input[type=password], input[type=submit], input[type=image], button {border-radius:0;-webkit-appearance:none}
textarea, select {font-size:1em;}
textarea {border-radius:0;-webkit-appearance:none}
p {margin:0;padding:0;word-break:break-all}
pre {overflow-x:scroll;font-size:1.1em}
a:link, a:visited {color:inherit;text-decoration:none}
ul,ol,li{list-style:none;padding:0;margin:0;}
input, button, textarea, select {font-family: 'Pretendard', sans-serif;outline: none}
hr {content: '';display:block;padding:0;border:0;margin: 0;}


/*사파리 버튼 초기화*/
input[type="text"], input[type="password"], input[type="button"], input[type="submit"], textarea { -webkit-appearance: none; -webkit-border-radius: 0; }


/*placeholder*/
input::-ms-input-placeholder { font-weight: 400; color: #cbcbcb; }
input::-webkit-input-placeholder { font-weight: 400; color: #cbcbcb; }
input::-moz-placeholder { font-weight: 400; color: #cbcbcb; }

textarea::-ms-input-placeholder { font-weight: 400; color: #cbcbcb; }
textarea::-webkit-input-placeholder { font-weight: 400; color: #cbcbcb; }
textarea::-moz-placeholder { font-weight: 400; color: #cbcbcb; }


/*드래그*/
::selection { background: #191919; color: #fff; }
::-moz-selection { background: #191919; color: #fff; }

.horizontal {
	display: flex;
	align-items: center;
}
.full-width {
	width: 100%;
}

.community-bottom-text { font-size: 16.4px; font-weight: 500; color: #fff; }

/*--------------------------------------------------------------------------------------------------------------------------*/


/*공통 CSS 1200px */
section { position: relative; height: 100vh; width: 100%; min-width: 320px; max-width: 680px; min-height: 100vh; margin: 0 auto; padding: 50px 0 80px; box-sizing: border-box; background-color: #ffffff }
.wrap { padding: 0 16px; box-sizing: border-box; background-color: #ffffff }


/*button style*/
.btn-custom,
a.btn-custom { display: flex; justify-content: center; align-items: center; width: 100%; height: 60px; border: none; background: #222; font-size: 18px; font-weight: 600; color: #fff; }
.btn-custom-v1,
a.btn-custom-v1 { border: 1px solid #000; box-sizing: border-box; background: #fff; color: #000; }
.btn-custom-v2,
a.btn-custom-v2 { width: 76px; min-width: 76px; height: 45px; border: 1px solid #9d9d9d; box-sizing: border-box; background: #fff; font-weight: 300; color: #8A8A8A; font-size: 16px; margin-left: 8px}
.btn-custom-v2-1,
a.btn-custom-v2-1 { width: 76px; min-width: 76px; height: 45px; border: 1px solid #222222; box-sizing: border-box; background: #fff; font-weight: 400; color: #222222; font-size: 16px; margin-left: 8px}
.btn-custom-v3,
a.btn-custom-v3 { box-sizing: border-box; background: #8A8A8A; color: #FFF; }
.btn-custom-v3-next,
a.btn-custom-v3-next { box-sizing: border-box; background: #222222; color: #FFF; }

.btn-group { display: flex; justify-content: space-between; align-items: center; }
.btn-group .btn { width: calc(50% - 4px); }


/*checkbox & radio button style*/
.ck-custom { display: none; }
.ck-custom + label { display: block; background: url('../img/ico_check1_off.svg') right center no-repeat; background-size: 24px; cursor: pointer; }
.ck-custom:checked + label { background-image: url('../img/ico_check1_on.svg'); }

.ck-custom2 { display: none; }
.ck-custom2 + label { display: block; background: url('../img/ico_check2_off.svg') left center no-repeat; background-size: 18px; cursor: pointer; padding: 16px 30px; box-sizing: border-box;}
.ck-custom2:checked + label { background-image: url('../img/ico_check2_on.svg'); }

/*thumbnail style*/
.thumb { width: 100%; background-position: center center; background-repeat: no-repeat; background-size: cover;}
/*.thumb-backimg1 {background-image: url('../img/img_profile1.png');}
.thumb-backimg2 {background-image: url('../img/img_picture1.png');}*/

/*header style*/
.header { position: fixed; top: 0; left: 0; right: 0; z-index: 100; width: 100%; min-width: 320px; max-width: 680px; margin: 0 auto; }
.hd { display: flex; justify-content: space-between; align-items: center; position: relative; height: 50px; padding: 0 16px; border-bottom: 1px solid #eee; box-sizing: border-box; background: #fff; }


.hd-v1 { border-bottom: none; width:100%; }
.hd-basket-type2 {
	width: 60%;
	height: 100%;
	float: right;
	padding-right: 8px;
}
.hd-on { display: inline-block;  width: 100%; height: 100%; background: url('../img/ico_on.svg') center right no-repeat; }
.hd-off { display: inline-block;  width: 100%; height: 100%; background: url('../img/ico_off.svg') center right no-repeat; }

.hd-basket {
	width: 10%;
	height: 100%;
}
.hd-cart-concierge { display: flex; justify-content: flex-end; align-items: flex-end;  width: 100%; height: 100%; background: url('../img/ico_cart2.svg') center right no-repeat; background-size: 40px 40px;}
.hd-cart-concierge .count { display: flex; justify-content: center; align-items: center; position: absolute; top: 11.4px; right: 15.8px; width: 14.6px; height: 14.6px; border-radius: 50%; box-sizing: border-box; background: #ec1d1d; font-size: 10px; font-weight: 500; color: #fff; letter-spacing: -0.05px; }

.hd-cart { display: inline-block;  width: 100%; height: 100%; background: url('../img/ico_cart1.svg') center right no-repeat; }
.hd-cart .count { display: flex; justify-content: center; align-items: center; position: absolute; top: 11.4px; right: 15.8px; width: 14.6px; height: 14.6px; border-radius: 50%; box-sizing: border-box; background: #ec1d1d; font-size: 10px; font-weight: 500; color: #fff; letter-spacing: -0.05px; }
.hd-logo {
	width: 30%;
	min-width: 110px;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.hd .hd-back { position: absolute; left: 0; z-index: 1; width: 50px; height: 100%; border: none; background: url('../img/ico_arrow_back1.svg') center center no-repeat; }
.hd .hd-tit { display: flex; justify-content: center; align-items: center; position: absolute; left: 0; right: 0; width: 100%; height: 100%; font-size: 18px; font-weight: 400; color: #000; }
.hd-v2 { border-color: #262626; background: #222; }
.hd-v2 .hd-back { background-image: url('../img/ico_arrow_back2.svg'); }
.hd-v2 .hd-tit { color: #fff; }

/*footer style*/
.footer { position: fixed; left: 0; right: 0; bottom: 0; z-index: 100; width: 100%; min-width: 320px; max-width: 680px; margin: 0 auto; background: #191919; /*border-top: 1px solid #8f8f8f;*/ box-sizing: border-box; }
.ft {width: 100%;}
.ft .ft-menu { width: 100%; display: flex; justify-content: space-between; align-items: center; height: 80px; border-top: 1px solid #8f8f8f;}
.ft .ft-menu a { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 25%; height: 100%; }
.ft .ft-menu .ico { width: 40px; height: 40px; margin-bottom: 4px; background: center center no-repeat; }


.ft .ft-menu2 { width: 100%; display: flex; justify-content: center; align-items: center; height: 80px; border-top: 1px solid #FFFFFF;}
.ft .ft-menu2 a { display: flex; flex-direction: column; justify-content: center; align-items: center; width: 25%; height: 100%; }
.ft .ft-menu2 .ico { width: 40px; height: 40px; margin-bottom: 4px; background: center center no-repeat; }
.ft .ft-menu2 .item1_on .ico { background-image: url('../img/ico_new_footer1.svg'); }
.ft .ft-menu2 .item2_on .ico { background-image: url('../img/ico_new_footer2.svg'); }
.ft .ft-menu2 .tit { font-size: 12.8px; font-weight: 400; color: #333333; letter-spacing: -0.22px; }
.ft .ft-home {
	background-color: #0a0a0a;
	width: 80px;
	height: 40px;
	color: #FFFFFF;
	font-weight: 600;
	font-size: 14.2px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	margin-left: 20px;
	margin-right: 20px;
}


.ft .ft-menu .item1_off .ico { background-image: url('../img/ico_footer1_off.svg'); }
.ft .ft-menu .item2_off .ico { background-image: url('../img/ico_footer2_off.svg'); background-size: 51px 52px;}
.ft .ft-menu .item3_off .ico { background-image: url('../img/ico_footer3_off.svg'); }
.ft .ft-menu .item4_off .ico { background-image: url('../img/ico_footer4_off.svg'); }
.ft .ft-menu .tit { font-size: 12.8px; font-weight: 400; color: #8a8a8a; letter-spacing: -0.22px; }

.ft .ft-menu .item1_on .ico { background-image: url('../img/ico_footer1_on.svg'); }
.ft .ft-menu .item2_on .ico { background-image: url('../img/ico_footer2_on.svg');background-size: 51px 52px;}
.ft .ft-menu .item3_on .ico { background-image: url('../img/ico_footer3_on.svg'); }
.ft .ft-menu .item4_on .ico { background-image: url('../img/ico_footer4_on.svg'); }

.ft .ft-menu .item1_on .tit { color: #fff }
.ft .ft-menu .item2_on .tit { color: #fff }
.ft .ft-menu .item3_on .tit { color: #fff }
.ft .ft-menu .item4_on .tit { color: #fff }

.ft-weather-view {
	width: 100%;
	height: 90px;
	max-height: 90px;
	vertical-align: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}
.bg-rain {background-image: url('../img/weather_rain.jpg');}
.bg-rain-stop {background-image: url('../img/weather_rain_stop.gif');}
.bg-snow {background-image: url('../img/weather_snow.jpg');}
.bg-snow-stop {background-image: url('../img/weather_snow_stop.jpg');}
.bg-heavy {background-image: url('../img/order_heavy.jpg');}
.bg-out-time {background-image: url('../img/vr_banner_out_time.jpg');}
.bg-break-time {background-image: url('../img/vr_break_time.jpg');}
.bg-vooroom-holiday {background-image: url('../img/vr_vooroom_holiday.jpg');}

.ft-weather-text1 {
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 18.6px;
	line-height: 140%;
	text-align: center;
	color: #F2F2F2;
}
.ft-weather-text1-1 {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 140%;
	text-align: center;
	color: #222222;
}
.ft-weather-text2 {
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	color: #F2F2F2;
}
.ft-weather-text2-1 {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #222222;
}

/*로그인*/
.login { background: #222; }
.login .img-logo { position: absolute; top: 40%; left: 50%; transform: translate(-50%, -50%) scale(1); -webkit-transform: translate(-50%, -50%) scale(1); -moz-transform: translate(-50%, -50%) scale(1); -ms-transform: translate(-50%, -50%) scale(1); -o-transform: translate(-50%, -50%) scale(1); }

.list-sns-login { position: absolute; left: 0; right: 0; bottom: 60px; width: calc(100% - 32px); margin: 0 auto; padding-bottom: 24px; }
.list-sns-login li { margin-bottom: 10px; }
.list-sns-login li:last-child { margin-bottom: 0; }
.list-sns-login .btn-sns-login { display: flex; justify-content: center; align-items: center; height: 40px; font-size: 15.8px; font-weight: 600; background: 16px center no-repeat; }
.list-sns-login .btn-sns-login-test { /*background-image: url('../img/ico_test1.svg');*/ background-color: #444444; color: #f1f1f1; }
.list-sns-login .btn-sns-login-kakao { /*background-image: url('../img/ico_kakao1.svg');*/ background-color: #fee500; color: #392020; }
.list-sns-login .btn-sns-login-naver { /*background-image: url('../img/ico_naver1.svg');*/ background-color: #03c75a; color: #fff; }
/*.list-sns-login .btn-sns-login-apple { background-image: url('../img/ico_apple1.svg'); background-color: #f2f2f2; color: #222; }*/
.btn-sns-login-apple { background-color: #FFFFFF; color: #222; display: flex; justify-content: center; align-items: center}
.login-onboarding-close {
	position: absolute;
	top: 26px;
	right: 0;
	z-index: 9;
	padding-right: 16px;
}
.login-onboarding-text {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 140%;
	text-align: center;
	color: #E4E4E4;
	padding-top: 25px;
}
.login-onboarding-bottom {
	width: 100%;
	position: absolute;
	bottom: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	text-decoration-line: underline;
	color: #E4E4E4;
	padding-bottom: 40px;
}

/*메뉴*/
.menu { padding-top: 50px; box-sizing: border-box; /*overflow-y: auto; scrollbar-width: none; -ms-overflow-style: none;*/}
/*.menu::-webkit-scrollbar {display: none;}*/
.menu .top-page {
	margin-bottom: 30px; padding: 13px 0; border-top: 1px solid #1a1a1a; border-bottom: 1px solid #1a1a1a; box-sizing: border-box; font-family: 'Nanum Myeongjo'; font-size: 24px; font-weight: 800; color: #000; text-align: center;
	width:calc(100% - 32px);
	max-width: 680px;
}
.wrap-top-page {
	width:100%;
	max-width: 664px;
	height: 60px;
	/*padding-top: 10px;*/
	position: fixed;
	background-color: #FFFFFF;
}
.wrap-list-category {
	padding-top: 80px;
	padding-bottom: 200px;
	width: calc(100%);

	/*background-image: url("../img/summer_menu_bg.png");
	background-size: contain;*/
}

.world-cup-view {
	width: 100%;
	height: 44px;
	background-color: #dfeded;
	border-radius: 4px;
	margin-bottom: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-company-info {
	border-top: 1px dashed #ccc;
	margin-top: 24px;
	padding-top: 20px;
	color: #8a8a8a;
	font-weight: 400;
	text-align: center;
}

.footer-company-info-name {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #8a8a8a;
	font-size: 11.8px;
}
.footer-company-info-name span {
	color: #4a4a4a;
	font-weight: 500;
}

.footer-company-info-menu {
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	font-size: 12.8px;
}

.footer-company-info-menu div {
	margin-right: 10px;
}

.list-category {
	min-height: calc(100vh - 330px);
}
.list-category a { display: block; padding: 12px 0; box-sizing: border-box; font-family: 'Nanum Myeongjo'; font-size: 20px; font-weight: 400; color: #000; text-align: center; letter-spacing: -0.1px; height: 48px !important; }

.list-category svg {
	margin-bottom: -2px;
}
	/*React 라이브러리 부분*/
.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
.swiper-slide img {display: block;width: 100%;height: 100%;object-fit: cover;}

/*메뉴 상세*/
.item-view { padding-bottom: 92px; box-sizing: border-box; overflow-y: auto;}
.slide-item { position: relative; background-color: #F8F8F8 /*background-image: url('../img/default.jpg'); background-size: cover;*/ }
.slide-item .swiper { height: 75vw; max-height: 270px; }
.slide-item .swiper-slide { position: relative; }
.slide-item .swiper-slide .thumb { height: 100%;}
.slide-item .swiper-slide .tit-item { display: flex; justify-content: center; align-items: center; position: absolute; left: 0; right: 0; bottom: 0; z-index: 1; width: 100%; height: 38px; background: rgba(34, 34, 34, 0.5); font-size: 14px; font-weight: 400; color: #fff; text-align: center; }
.slide-item .pagination-group { position: absolute; top: 16px; right: 16px; z-index: 1; }
.slide-item .pagination-group .swiper-pagination { display: flex; justify-content: center; align-items: center; position: static; width: auto; height: 20px; padding: 0 10px; border-radius: 18px; box-sizing: border-box; background: rgba(34, 34, 34, 0.5); font-size: 10px; font-weight: 500; color: #fff; }

.list-item li { position: relative; padding: 20px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-item .item-name { margin-bottom: 5px; font-size: 16px; font-weight: 500; color: #1a1a1a; display: flex; align-items: center;}
.list-item .item-store { font-family: 'Pretendard'; margin-bottom: 16px; font-size: 12px; font-weight: 400; color: #9D9D9D; display: flex; margin-left: -2px; margin-top: 8px}
.list-item .item-store .item-store-round {  height: 22px; border: 0.5px solid #9D9D9D; border-radius: 20px; display: flex; justify-content: center; align-items: center; padding-left: 8px; padding-right: 8px;  text-overflow: ellipsis; white-space: nowrap;}
.list-item .item-price { font-size: 16px; font-weight: 500; color: #1a1a1a; letter-spacing: -0.32px; }
.list-item .item-count { display: flex; justify-content: center; align-items: center; position: absolute; right: 0; bottom: 20px; }
.list-item .item-count .btn-count { width: 28px; height: 28px; border: none; border-radius: 50%; box-sizing: border-box; background: center center no-repeat; }
.list-item .item-count .btn-count-minus { background-image: url('../img/ico_minus1_on.svg'); }
.list-item .item-count .btn-count-minus:disabled { background-image: url('../img/ico_minus1_off.svg'); }
.list-item .item-count .btn-count-plus { background-image: url('../img/ico_plus1_on.svg'); }
.list-item .item-count .btn-count-plus:disabled { background-image: url('../img/ico_plus1_off.svg'); }
.list-item .item-count .num { width: 38px; font-size: 16px; font-weight: 500; color: #1a1a1a; text-align: center; letter-spacing: -0.32px; }
.list-item .btn-delete { display: flex; justify-content: center; align-items: center; position: absolute; top: 20px; right: 0; width: 20px; height: 20px; border: none; background: url('../img/ico_x1.svg') center center no-repeat; }
.list-item .item-name span { font-style: normal; font-weight: 400; font-size: 12px; color: #EC1D1D; margin-left: 8px; }
.list-item .item-together span { font-style: normal; font-weight: 400; font-size: 12px; color: #1876b7; margin-left: 8px; }

.item-view .order-info { padding: 30px 0; box-sizing: border-box; /*padding-bottom: 120px*/}
.list-order-info li { display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; }
.list-order-info li:last-child { margin-bottom: 0; }
.list-order-info li.v1 { align-items: flex-start; }
.list-order-info .tit { font-size: 16px; font-weight: 400; color: #1a1a1a; }
.list-order-info .txt { font-size: 16px; font-weight: 500; color: #1a1a1a; text-align: right; letter-spacing: -0.32px; }
.list-order-info .txt strong { margin-right: 2px; }
.list-order-info .txt2 { font-size: 14px; font-weight: 400; color: #1a1a1a; text-align: right; letter-spacing: -0.28px; }
.list-order-info .txt2 p { margin-bottom: 4px; }
.list-order-info .txt2 p:last-child { margin-bottom: 0; }
.list-order-info .txt2 p span { margin-right: 4px; }
.list-order-info .txt2 p span:last-child { margin-right: 0; }
.order-info-etc { margin-top: 15px; line-height: 17px; font-size: 12.6px; font-weight: 400; color: #7d7d7d; }

.btn-floating-bottom { position: fixed; left: 0; right: 0; bottom: 0; z-index: 100; margin: 0 auto; padding: 16px; box-sizing: border-box; background-color: #fff; width: 100%; min-width: 320px; max-width: 680px; border: none}
.btn-floating-bottom .btn-cart { display: flex; justify-content: center; align-items: center; position: relative; width: 100%; height: 60px; border: none; background: #222; }
.btn-floating-bottom .btn-cart .total-count { font-size: 18px; font-weight: 600; color: #fff; }
.btn-floating-bottom .btn-cart .total-price { position: absolute; right: 20px; font-size: 18px; font-weight: 500; color: #fff; }


/*주문*/
.order { padding-bottom: 92px; box-sizing: border-box; }
.order-empty {
	font-size: 18px;
	font-weight: 400;
	color: #1a1a1a;
	line-height: 29.6px;
}
.order-group {padding-bottom: 92px; background-color: #FFFFFF; overflow-y: auto;}
.box-order-menu { padding: 24px 16px 0; border-bottom: 8px solid #f6f6f6; box-sizing: border-box; }
.box-order-menu .btn-delete-all { display: flex; justify-content: center; align-items: center; width: 62px; height: 24px; margin: 0 0 0 auto; border: 1px solid #222; border-radius: 11px; box-sizing: border-box; background: none; font-size: 12px; font-weight: 500; color: #222; }
.box-order-menu .list-item li:last-child { border-bottom: none; }
.box-order-info { padding: 24px 16px; border-bottom: 8px solid #f6f6f6; box-sizing: border-box; }
.box-order-total { padding: 24px 16px; border-bottom: none; box-sizing: border-box; }

.list-total-price li { display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; }
.list-total-price li:last-child { margin-bottom: 0; }
.list-total-price li.v1 { align-items: flex-start; }
.list-total-price .tit { font-size: 18px; font-weight: 700; color: #1a1a1a; }
.list-total-price .txt { font-size: 18px; font-weight: 500; color: #1a1a1a; text-align: right; letter-spacing: -0.36px; }
.list-total-price .txt strong { margin-right: 2px; }

.no-data { display: block; }
.no-data.active { display: block; }
.no-data .txt-group { position: absolute; top: 45%; left: 50%; transform: translate(-50%, -50%) scale(1); -webkit-transform: translate(-50%, -50%) scale(1); -moz-transform: translate(-50%, -50%) scale(1); -ms-transform: translate(-50%, -50%) scale(1); -o-transform: translate(-50%, -50%) scale(1); width: 100%; text-align: center; line-height: 29.6px; }
.no-data .txt-group .txt { font-size: 18px; font-weight: 400; color: #9d9d9d; }
.no-data .txt-group .txt2 { font-size: 18px; font-weight: 600; color: #1a1a1a; }


/*결제하기*/
.payment { padding-bottom: 92px; box-sizing: border-box; }

.box-payment-discount { padding: 24px 16px; border-bottom: 8px solid #f6f6f6; box-sizing: border-box; }
.list-payment-discount li { display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px; }
.list-payment-discount li:last-child { margin-bottom: 0; }
.list-payment-discount .discount-type { display: flex; align-items: center; }
.list-payment-discount .discount-type .btn-discount { display: flex; justify-content: center; align-items: center; width: 76px; height: 28px; margin-right: 12px; border: none; background: #4e4e4e; font-size: 14px; font-weight: 500; color: #fff; }
.list-payment-discount .discount-type .discount-label { font-size: 14px; font-weight: 500; color: #1a1a1a; }
.list-payment-discount .discount-price { font-size: 14px; font-weight: 500; color: #1a1a1a; text-align: right; letter-spacing: -0.28px; }
.list-payment-discount .discount-price span { margin-right: 2px; }


/*쿠폰함*/
.coupon { padding-bottom: 164px; box-sizing: border-box; }

.box-coupon { padding: 24px 16px; box-sizing: border-box; }
.list-coupon li { border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-coupon .ck-custom + label { padding: 20px 0; box-sizing: border-box; }
.list-coupon .ck-custom + label .custom { display: flex; justify-content: space-between; width: calc(100% - 52px); }
.list-coupon .ck-custom + label .tit { width: 52%; font-size: 14px; font-weight: 500; color: #1a1a1a; }
.list-coupon .ck-custom + label .date { width: 40%; font-size: 14px; font-weight: 300; color: #1a1a1a; }
.list-coupon .ck-custom + label .count { width: 8%; font-size: 14px; font-weight: 600; color: #1a1a1a; text-align: right; }

.btn-floating-bottom-v1 { padding: 24px 16px 16px; border-top: 8px solid #f6f6f6; box-sizing: border-box; }
.btn-floating-bottom-v1 .list-total-price { margin-bottom: 35px; }


/*포인트 사용*/
.point .wrap { padding: 24px 16px; box-sizing: border-box; }

.inp-group-point { display: flex; justify-content: space-between; align-items: center; margin-bottom: 9px; }
.inp-group-point .inp-frm { width: calc(100% - 84px); height: 40px; margin-right: 8px; padding: 0 14px; border: 1px solid #cbcbcb; box-sizing: border-box; font-size: 14px; font-weight: 400; color: #1a1a1a; }
.inp-group-point .btn-frm { width: 76px; height: 40px; border: 1px solid #1a1a1a; box-sizing: border-box; background: none; font-size: 14px; font-weight: 400; color: #1a1a1a; }

.point-info { font-size: 12px; font-weight: 400; color: #7a7a7a; }
.point-info span { margin-left: 8px; font-size: 12px; font-weight: 600; color: #1a1a1a; }


/*결제완료*/
.complete-message { position: absolute; top: 45%; left: 50%; transform: translate(-50%, -50%) scale(1); -webkit-transform: translate(-50%, -50%) scale(1); -moz-transform: translate(-50%, -50%) scale(1); -ms-transform: translate(-50%, -50%) scale(1); -o-transform: translate(-50%, -50%) scale(1); width: 100%; text-align: center; }
.complete-message svg { display: block; margin: 0 auto 25px; }
.complete-message .txt { font-size: 18px; font-weight: 400; color: #1a1a1a; line-height: 29.6px; }
.txt2 { font-size: 20px; font-weight: 600; color: #1a1a1a; line-height: 29.6px; }
.txt3 { font-size: 14px; font-weight: 300; color: #8a8a8a; line-height: 120%; margin-top: 8px }
/*내 설정*/
.setting { padding-bottom: 0; }
.setting .top { padding-top: 12px; padding-bottom: 25px; border-bottom: 8px solid #f6f6f6; box-sizing: border-box; background: #222; }

.user-profile,
.user-address { display: flex; align-items: center; padding: 12px 16px; box-sizing: border-box; }
.user-profile .img-group,
.user-address .img-group { width: 55px; height: 55px; margin-right: 12px; }
.user-profile .img-group .thumb,
.user-address .img-group .thumb { height: 100%; border-radius: 50%; box-sizing: border-box; }
.user-profile .user-info,
.user-address .address-info { width: calc(100% - 67px); }
.user-profile .user-info .user-name { font-size: 18px; font-weight: 500; color: #f2f2f2; }
.user-profile .user-info .user-email { margin-top: 5px; font-size: 14px; font-weight: 300; color: #f2f2f2; }
.user-address .address-info .apt-name { margin-bottom: 5px; font-size: 16px; font-weight: 500; color: #f2f2f2; }
.user-address .address-info .apt-detail { margin-top: 10px; font-size: 14px; font-weight: 300; color: #fff; }
.user-address .address-info .apt-detail span { margin-right: 8px; }
.user-address .address-info .apt-detail span:last-child { margin-right: 0; }

.user-state { display: flex; justify-content: space-between; margin-top: 26px; padding: 0 16px; box-sizing: border-box; }
.user-state li { position: relative; width: 50%; }
.user-state li:after { content: ''; display: block; position: absolute; top: calc(50% - 21px); right: 0; width: 1px; height: 42px; background: #5f5f5f }
.user-state li:last-child:after { display: none; }
.user-state a { display: flex; flex-direction: column; justify-content: center; align-items: center; }
.user-state .ico { display: block; margin: 0 auto 3px; }
.user-state .txt { font-size: 14px; font-weight: 400; color: #fff; text-align: center; }

.list-setting-menu { padding-bottom: 50px; box-sizing: border-box; background-color: #FFFFFF; }
.list-setting-menu a { display: flex; align-items: center; height: 50px; padding: 0 16px; box-sizing: border-box; background: url('../img/ico_arrow_right1.svg') calc(100% - 16px) center no-repeat; font-size: 16px; font-weight: 400; color: #1a1a1a; }


/*내 설정 > 나의 아파트*/
.address {padding-bottom: 92px; box-sizing: border-box; background-color: #FFFFFF;}

.address-fixed {
	position: fixed;
	background-color: #FFFFFF;
	width: 100%;
}
.box-search
{
	padding: 24px 16px;
	border-bottom: 8px solid #f6f6f6;
	box-sizing: border-box;
	background-color: #FFFFFF;
}
.inp-group-search { display: flex; justify-content: space-between; align-items: center; height: 45px; border: 1px solid #1a1a1a; box-sizing: border-box; }
.inp-group-search .inp-search { width: 100%; height: 100%; padding: 0 12px; border: none; font-size: 16px; font-weight: 400; color: #1a1a1a; }
.inp-group-search .btn-search { width: 45px; height: 100%; border: none; background: url('../img/ico_search1.svg') center center no-repeat; }

.box-address
{
	padding: 24px 16px;
	border-bottom: 8px solid #f6f6f6;
	box-sizing: border-box;
	background-color: #FFFFFF;
}
.box-address .inp-frm { width: 100%; height: 40px; margin-bottom: 8px; padding: 0 12px; border: 1px solid #9d9d9d; box-sizing: border-box; font-size: 16px; font-weight: 400; color: #1a1a1a;
	-webkit-text-fill-color: #1a1a1a;
	opacity: 1; /* required on iOS */}
.inp-group-address { display: flex; justify-content: space-between; align-items: center; }
.inp-group-address .inp-addr { display: flex; justify-content: space-between; align-items: center; width: calc(50% - 4px); height: 40px; padding: 0 15px; border: 1px solid #9d9d9d; box-sizing: border-box; }
.inp-group-address .inp-addr input { width: 80%; height: 100%; border: none; font-size: 16px; font-weight: 400; color: #1a1a1a; }
.inp-group-address .inp-addr span { width: 20%; font-size: 12px; font-weight: 400; color: #7d7d7d; text-align: right; }

.list-address {
	background-color: #FFFFFF;
}
.list-address li { border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-address .ck-custom + label { padding: 20px 16px; box-sizing: border-box; background-position: calc(100% - 16px) center; background-size: 20px; font-size: 16px; font-weight: 400; color: #1a1a1a; }

.address-temp-info {
	width: 100%;
	min-height: 240px;
	font-weight: 400;
	line-height: 140%;
	font-size: 14.8px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #7D7D7D;
	background-color: #FFFFFF;
	padding-bottom: 100px;
}

/*내 설정 > 쿠폰함*/
.mycoupon .top { display: flex; flex-direction: column; justify-content: center; height: 113px; padding: 0 16px; box-sizing: border-box; background: #222; }

.inp-group-mycoupon { display: flex; justify-content: space-between; align-items: center; }
.inp-group-mycoupon .inp-frm { width: calc(100% - 88px); height: 40px; margin-right: 8px; padding: 0 12px; border: none; background: #4a4a4a; font-size: 14px; font-weight: 400; color: #cbcbcb; }
.inp-group-mycoupon .btn-frm { display: flex; justify-content: center; align-items: center; width: 80px; height: 40px; border: 1px solid #f2f2f2; box-sizing: border-box; background: none; font-size: 14px; font-weight: 600; color: #f2f2f2; }

.list-coupon-history li { display: flex; justify-content: space-between; align-items: center; padding: 21px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-coupon-history .tit { width: 52%; font-size: 14px; font-weight: 500; color: #1a1a1a; }
.list-coupon-history .date { width: 40%; font-size: 14px; font-weight: 300; color: #1a1a1a; }
.list-coupon-history .count { width: 8%; font-size: 14px; font-weight: 600; color: #1a1a1a; text-align: right; }


/*내 설정 > 포인트*/
.mypoint .top { height: 113px; padding: 53px 16px 20px; box-sizing: border-box; background: #222; }

.mypoint .point-state { display: flex; justify-content: space-between; align-items: flex-end; }
.mypoint .point-state .tit { font-size: 16px; font-weight: 400; color: #f2f2f2; }
.mypoint .point-state .num { font-size: 18px; font-weight: 400; color: #f2f2f2; }
.mypoint .point-state .num span { margin-right: 4px; font-size: 30px; font-weight: 600; letter-spacing: -1px; }

.list-point-history li { display: flex; justify-content: space-between; align-items: center; padding: 21px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-point-history li > div { width: 33.33%; }
.list-point-history .sort { font-size: 14px; font-weight: 500; color: #1a1a1a; }
.list-point-history .date { font-size: 14px; font-weight: 400; color: #7d7d7d; text-align: center; }
.list-point-history .num { font-size: 14px; font-weight: 600; color: #1a1a1a; text-align: right; }
.list-point-history .num-v1 { color: #ec1d1d; }


/*내 설정 > 주문내역*/
.order-view { padding-bottom: 68px; box-sizing: border-box; }
.order-view .box { padding: 24px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.order-view .box:first-child { padding: 36px 0; box-sizing: border-box; }
.order-view .box:last-child { border-bottom: none; }
.box-title {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 164.34%;
	display: flex;
	align-items: center;
	color: #1A1A1A;
	margin-bottom: 12px;
}
.order-view-detail-label {
	width: 56px;
	height: 24px;
	line-height: 24px;
	background-color: #222222;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	color: #FFFFFF;
	margin-bottom: 18px;
}
.box-txt-bold {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	display: flex;
	align-items: center;
	color: #1A1A1A;
}

.state-message { margin-bottom: 24px; font-size: 18px; color: #1a1a1a; text-align: center; line-height: 26px; }
.state-message .tit { font-weight: 600; }
.state-message .txt { font-weight: 400; }

.progress-state .circle-group { display: flex; justify-content: space-between; align-items: center; width: 272px; margin: 0 auto 14px; }
.progress-state .circle-group .ico { position: relative; width: 26px; height: 26px; background: url('../img/ico_circle1_normal.svg') center center no-repeat; }
.progress-state .circle-group .ico.active { background-image: url('../img/ico_circle1_on.svg'); }
.progress-state .circle-group .ico.inactive { background-image: url('../img/ico_circle1_off.svg'); }
.progress-state .circle-group .ico:after { content: ''; display: block; position: absolute; top: 12px; right: calc(50% - 120px);  width: 107px; height: 2px; background: url('../img/img_line1_off.svg') center center; }
.progress-state .circle-group .ico:last-child:after { display: none; }
.progress-state .circle-group .ico.active:after { background-image: url('../img/img_line1_on.svg'); }
.progress-state .txt-group { display: flex; justify-content: space-between; align-items: center; width: 302px; margin: 0 auto; }
.progress-state .txt-group .txt { font-size: 14px; font-weight: 500; color: #1a1a1a; text-align: center; }
.progress-state .txt-group .txt:nth-child(2) { position: absolute; left: 0; right: 0; margin: 0 auto; }
.progress-state .info-group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 24px;
}

.list-order-data li { display: flex; align-items: center; margin-bottom: 9px; }
.list-order-data li:last-child { margin-bottom: 0; }
.list-order-data .tit { width: 64px; font-size: 14px; font-weight: 400; color: #1a1a1a; }
.list-order-data .txt { font-size: 14px; font-weight: 400; color: #1a1a1a; width: 80%}
.list-order-data .txt span { margin-right: 4px; }
.list-order-data .txt span:last-child { margin-right: 0; }
.list-order-data .txt strong { font-weight: 600; }

.list-order-menu li { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
.list-order-menu li:last-child { margin-bottom: 0; }
.list-order-menu .tit { width: 60%; font-size: 14px; font-weight: 400; color: #1a1a1a; }
.list-order-menu .count { width: 15%; font-size: 14px; font-weight: 400; color: #1a1a1a; text-align: right; }
.list-order-menu .count span { font-weight: 500; margin-right: 3px; }
.list-order-menu .price { width: 25%; font-size: 14px; font-weight: 400; color: #1a1a1a; text-align: right; }
.list-order-menu .price span { font-weight: 500; margin-right: 3px; }

.order-view .order-info { padding: 24px 0; box-sizing: border-box; }
.order-view .list-total-price .tit { font-size: 16px; }
.order-view .list-total-price .txt { font-size: 16px; }

.order-view .btn-floating-bottom { padding: 0; border-top: 8px solid #f6f6f6; box-sizing: border-box; }
.order-view .btn-floating-bottom .btn-history { display: flex; justify-content: space-between; align-items: center; height: 60px; padding: 0 16px; box-sizing: border-box; background: url('../img/ico_arrow_right1.svg') calc(100% - 16px) center no-repeat; background-size: 12px; font-size: 16px; font-weight: 600; color: #1a1a1a; }


/*내 설정 > 이전 주문내역*/
.list-order-history li { display: flex; align-items: center; padding: 21px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.list-order-history .state { width: 18%; font-size: 14px; font-weight: 400; color: #1a1a1a; }
.list-order-history .date { width: 20%; font-size: 12px; font-weight: 300; color: #1a1a1a; }
.list-order-history .detail { width: 40%; font-size: 14px; font-weight: 500; color: #1a1a1a; }
.list-order-history .price { width: 20%; font-size: 14px; font-weight: 600; color: #1a1a1a; text-align: right; }


/*내 설정 > 상세내역*/
.order-detail { padding-bottom: 0; }
.order-detail .box { padding: 24px 0; border-bottom: 1px solid #ececec; box-sizing: border-box; }
.order-detail .box:last-child { border-bottom: none; }
.order-detail .box .state { display: inline-flex; justify-content: center; align-items: center; height: 24px; margin-bottom: 17px; padding: 0 7px; box-sizing: border-box; background: #222; font-size: 12px; font-weight: 400; color: #fff; }
.order-detail .top-tit { margin-bottom: 12px; font-size: 16px; font-weight: 600; color: #1a1a1a; }
.order-detail .list-order-data .txt { font-weight: 500; }

.list-payment-info li { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
.list-payment-info li:last-child { margin-bottom: 0; }
.list-payment-info .tit { font-size: 14px; font-weight: 400; color: #1a1a1a; }
.list-payment-info .txt { font-size: 14px; font-weight: 500; color: #1a1a1a; text-align: right; letter-spacing: -0.28px; }
.list-payment-info .txt span { margin-right: 3px; font-weight: 600; }

.order-detail .list-total-price { margin-bottom: 14px; }

/*내 설정 > TextView */
.setting-tv { padding-bottom: 92px; box-sizing: border-box; }
.setting-tv-wrap { padding: 12px; background-color: #FFFFFF;}
.setting-tv-text {
	font-family: 'Pretendard';
	font-weight: 300;
	font-size: 15.4px;
	line-height: 140%;
	color: #1A1A1A;
	background-color: #FFFFFF;
}
.event-view {
	text-align: center;
	justify-content: center;
	align-items: center;
}
.cs-item-view {
	height: 70px;
	border-bottom: 8px solid #F7F7F7;
	margin-left: -12px;
	margin-right: -12px;
	display: flex;
	align-items: center;
	padding-bottom: 8px;
	padding-left: 17px;
	padding-right: 17px;
}
.cs-item-title {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #1A1A1A;
	margin-right: 19px;
	min-width: 64px;
}
.cs-item-body {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	color: #373737;
}
.cs-item-call {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: right;
}
.cs-faq {
	padding-top: 24px;
	padding-left: 6px;
	padding-bottom: 68px;
}

/*휴대폰 인증*/
.auth-phone { padding-bottom: 92px; box-sizing: border-box; }
.auth-phone-txt {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: #1A1A1A;
	padding-left: 17px;
	padding-top: 24px;
}
.auth-phone-btn {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 8px;
}
.auth-phone-timer {
	position: absolute;
	right: 0;
	margin-right: 30px;
	line-height: 45px;
	font-weight: 400;
	font-size: 14px;
	color: #EC1D1D;
}
.box-auth-phone { padding: 8px 16px 0px 16px; box-sizing: border-box; display: flex;}
.box-auth-phone input[type="number"] {-webkit-appearance: none;}
.box-auth-phone .inp-frm { width: 100%; height: 45px; margin-bottom: 8px; padding: 0 12px; border: 1px solid #9d9d9d; box-sizing: border-box; font-size: 16px; font-weight: 400; color: #1a1a1a; -webkit-appearance: none; border-radius: 0 }

/*약관동의*/
.terms { padding-bottom: 164px; box-sizing: border-box; }
.terms-info {
	padding: 30px 16px 12px 16px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	align-items: center;
	color: #1A1A1A;
}
.terms-area {
	padding: 0px 16px 24px 16px;
}
.terms-all {
	border-bottom: 1px solid #eeeeee;
}
.terms-all-txt {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: #1A1A1A;
}
.terms-item {
	display: flex;
	width: 100%;
}
.terms-item-right {
	text-align: right;
	display: flex;
	align-items: center;
}
.terms-item-txt {
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	color: #1A1A1A;
}
.user-address-empty {
	padding: 8px 16px !important;
}
.user-address-empty-box {
	border: 1px solid #F2F2F2;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	text-align: center;
	color: #F2F2F2;
	height: 40px;
	line-height: 40px;
}

/* 아파트 선택 스플래시 */
.apt_splash {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	animation: fadein 1.5s;
	-webkit-animation: fadein 1.5s;
}
.apt_splash_img1 {
	background-image: url("../img/apt_splash_1.jpg");
	background-size: 100% 100%;
}
.apt_splash_img2 {
	background-image: url("../img/apt_splash_2.jpg");
	background-size: 100% 100%;
}
.apt_splash_img3 {
	background-image: url("../img/apt_splash_3.jpg");
	background-size: 100% 100%;
}
.apt_splash_img4 {
	background-image: url("../img/apt_splash_4.jpg");
	background-size: 100% 100%;
}
.event_splash_img {
	background-image: url("../img/world_cup_splash.jpg");
	background-size: 100% 100%;
}

/* 로딩패널 */
.spinner-view {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9;
}

@keyframes fadein {
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-moz-keyframes fadein { /* Firefox */
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-webkit-keyframes fadein { /* Safari and Chrome */
	from {
		opacity:0;
	}
	to {
		opacity:1;
	}
}
@-o-keyframes fadein { /* Opera */
	from {
		opacity:0;
	}
	to {
		opacity: 1;
	}
}


/* 팝업창 Alert */
.overlay-custom-class-name-type2 {
	background: rgba(200, 200, 200, 0.1);
	animation: react-confirm-alert-fadeIn 0.0s 0.0s forwards;
	filter: blur(0px);
	z-index: 1000;
}
.overlay-custom-class-name {
	background: rgba(26, 26, 26, 0.3);
	animation: react-confirm-alert-fadeIn 0.0s 0.0s forwards;
	filter: blur(0px);
	z-index: 1000;
}
.react-confirm-alert-blur {
	filter: none;
}
.react-confirm-custom {
	width: 280px;
	background: #1A1A1A;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
}
.react-confirm-custom-wrap {
	width: 280px;
}
.react-confirm-custom-msg {
	width: 100%;
	height: 100%;
	padding-top: 24px;
	padding-bottom: 24px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #F2F2F2;
}
.react-confirm-custom-btn {
	width: 100%;
	height: 60px;
	text-align: center;
}
.react-confirm-custom-btn button {
	width: 124px;
	height: 36px;
	background-color: #1A1A1A;
	border: 1px solid #F2F2F2;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #F2F2F2;
}
.react-confirm-custom-v2 {
	width: 220px;
	height: 48px;
	background: #1A1A1A;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
}
.react-confirm-custom-wrap-v2 {
	width: 220px;
	height: 48px;
}
.react-confirm-custom-msg-v2 {
	width: 220px !important;
	height: 48px !important;
	line-height: 18px !important;
	display: inline-block !important;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: #F2F2F2;
}

.react-confirm-custom-emergency {
	width: 100%;
	height: 100%;
	padding-top: 24px;
	padding-bottom: 24px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #F2F2F2;
}

.react-confirm-custom-emergency-title {
	font-weight: 600;
	font-size: 16px;
}

.react-confirm-custom-holiday-title {
	font-weight: 400;
	font-size: 20px;
}

.footer-info {
	padding: 10px;
}
.footer-info p {
	color: #8a8a8a;
	text-align: center;
	font-size: 10px;
	line-height: 16px;
}

.footer-info2 {
	background-color: #FFFFFF;
	margin-top: 0px;
	padding-top: 40px;
	margin-bottom: -60px;
	border-top: 1px solid #eeeeee;
}
.footer-info2 p {
	color: #333333;
	text-align: center;
	font-size: 10px;
	line-height: 16px;
}

/* menu detail popup view */
.menu-popup-view {
	background-color: #FFFFFF;
	width: 100%;
	max-width: 340px;
	height: 260px;
	display: flex;
	position: relative;
}
.menu-popup-view svg {
	display: flex;
	position: absolute;
	right: 0;
	width: 24px;
	height: 24px;
	margin-top: 4px;
	margin-right: 5px;
}
.menu-popup-bottom {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width: 100%;
	height: 38px;
	background: rgba(34, 34, 34, 0.65);
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	text-align: center;
}


/* popup-notice */
.popup-notice-view {
	background-color: #FFFFFF;
	width: 320px;
	height: 440px;
}
.popup-notice-open-img-time-a {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup1_bg_time_a.jpg')
}
.popup-notice-open-img-time-b {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup1_bg_time_b.jpg')
}
.popup-notice-open-img-time-a-weekend {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup1_bg_time_a_weekend.jpg')
}
.popup-notice-open-img-time-b-weekend {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup1_bg_time_b_weekend.jpg')
}
.popup-notice-open-img2 {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup_service_show2.png')
}
.popup-notice-open-img3 {
	width: 320px;
	height: 360px;
	padding-bottom: 40px;
	resize: both;
	background-size: cover;
	background-image: url('../img/popup_service_show3.jpg')
}
.popup-notice-open-btn {
	width: 320px;
	height: 40px;
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: 300;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: -0.5px;
	color: #122222;
}

/* popup-notice */
.onboarding-view {
	background-color: #222222;
	width: 100%;
	height: 100vh;
}
.onboarding-view img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.onboarding-view-step0 {
	background-color: #222222;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}
.onboarding-view-step0 img {
	width: 100%;
	max-width: 360px;
	max-height: 640px;
}
.onboarding-view-step0-text {
	position: absolute;
	bottom: 470px;
	left: 50%;
	transform: translate(-50%, -50%);
}
.onboarding-view-step0-btn {
	position: absolute;
	bottom: 390px;
	left: 50%;
	transform: translate(-50%, -50%);
}
.onboarding-skip {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #918F8F;
	padding-left: 27px;
	z-index: 9;
}
.onboarding-btn {
	background-color: #222222;
	display: flex;
	position: absolute;
	top: 26px;
	width: 100%;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	color: rgba(255, 255, 255, 0.8);
}
.onboarding-btn2 {
	background-color: #222222;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 140%;
	text-align: center;
	color: rgba(255, 255, 255, 0.8);
	padding-top: 26px;
}
.onboarding-btn-prev {
	position: absolute;
	top: 50%;
	left: 35px;
	transform: translate(-50%, -50%);
	z-index: 100;

}
.onboarding-btn-next {
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translate(-50%, -50%);
	z-index: 100;

}

/* notice */
.notice-date {
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	display: flex;
	align-items: center;
	color: #989898;
}
.notice-view {
	padding: 16px 0px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 400;
	font-size: 14.8px;
	line-height: 160%;
	color: #373737;
}
.notice-title {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 75%;
	min-width: 75%;
	font-size: 15.4px !important;
}

/* 주문내역 */
.order-count-view {
	width: 60px;
	display: flex;
	justify-content: flex-end;
}
.order-count {display: flex; justify-content: center; align-items: center;  width: 14.6px; height: 14.6px; border-radius: 50%; box-sizing: border-box; background: #ec1d1d; font-size: 10px; font-weight: 500; color: #fff; letter-spacing: -0.05px; }

/*
@media only screen and (max-width: 1200px) {
	section { max-width: 100%; }
}
*/

/*
 부룸 UX 1.5
 */
.v15-home {
	background-color: #f8f8f8;
	padding-bottom: 120px;
	overflow-y: auto;
}
.v15-menu-box-zone {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;

}
.v15-menu-box {
	margin-left: 6px;
	margin-right: 6px;
	width: 45%;
	height: 100px;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.08);
	padding-left: 4px;
	padding-top: 4px;
}
.v15-menu-box-icon {
	width: 100%;
	max-width: 44px !important;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 4px;
}
.v15-menu-box-icon2 {
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.v15-menu-box-title {
	width: 100%;
	min-width: 100px;
	height: 40px;
	line-height: 40px;
	font-size: 17.8px;
	font-weight: 600;
	text-align: center;
}
.v15-menu-box-body-zone {
	text-align: center;
	width: 100%;
}
.v15-menu-box-body {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.6px;
}

.v15-swiper-wrap {
	width: 100%;
}
.v15-swiper-zone {
	width: 92%;
	height: 80px;
	margin-bottom: 16px;
	background-color: #f6f6f6 !important;
}
.v15-swiper-slide {
	width: 100%;
	height: 80px;
	border-radius: 16px;
	background-color: #f6f6f6 !important;
	resize: both;
	background-size: cover;
}
.v15-swiper-slide-img-01 {
	background-image: url('../img/top_banner_01.png')
}
.v15-swiper-slide-img-02 {
	background-image: url('../img/top_banner_02.png')
}
.v15-swiper-slide-img-03 {
	background-image: url('../img/top_banner_03.png')
}
.v15-home .swiper-slide {
	background-color: #f6f6f6 !important;
}
.v15-card-list-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 14px;
}
.v15-card {
	background-color: #eee;
	width: 91%;
	height: 76px;
	border-radius: 10px;
	display: flex;
}
.home-card-list-img-wrap {
	width: 80px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 12px;
	padding-right: 12px;
}
.home-card-list-img-tumb {
	width: 72px;
	height: 54px;
	border-radius: 4px;
}
.home-card-list-title {
	font-size: 15.6px;
	color: #4a4a4a;
	font-weight: 600;
	height: 24px;
	line-height: 24px;
	width: 200px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}
.home-card-list-body {
	font-size: 13.8px;
	font-weight: 400;
	color: #616161;
	height: 22px;
	line-height: 22px;
	width: 220px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
}
.community-list-view {
	padding: 8px;
	height: 100%;
	overflow-y: auto;
	padding-bottom: 120px;
	background-color: #FFFFFF;
}
.community-list-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 8px;
}
.community-list-row-item {
	width: 50%;
	text-align: center;
}
.community-list-row-img {
	width: 90%;
	border-radius: 8px;
	margin-bottom: 10px;
}
.community-list-row-item-text1 {
	text-align: left !important;
	padding-left: 12px;
	font-size: 14px;
	font-weight: 600;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
	margin-bottom: 4px;
	min-height: 17px;
}
.community-list-row-item-text1-1 {
	text-align: left !important;
	padding-left: 12px;
	font-size: 12.8px;
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
	margin-bottom: 4px;
	min-height: 14.5px;
	color: #333333;
}
.community-list-row-item-text2 {
	text-align: left !important;
	padding-left: 12px;
	padding-right: 14px;
	font-size: 12px;
	color: #8A8A8A;
	margin-bottom: 4px;
	line-height: 140%;
	min-height: 34px;
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}